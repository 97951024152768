








































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class Index extends Mixins(Methods) {
	created(): void {
		this.getDemand()
		this.getStore()
	}
}
